
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Grid, Box, Menu, MenuItem, IconButton, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomFooter from '../components/footer/CustomFooter';
import CardContainer from '../components/card/CardContainer';
import { fetchCardDetails } from '../axios/api-service';
import MainCard from '../pages/landing/MainCard';
import SuccessStories from '../components/card/SuccessStories';
import OFNavbar from '../components/header/OFNavbar';
import Banner from '../components/header/Banner';
import ExpertTalk from '../components/header/ExpertTalk';
import InvestorBanner from '../components/header/InvestorBanner';


import { OFIdeaCard, OFIdeaCardFinal, OFShadow, OFVideoLink } from '../icons/CustomIcon';
import IdeaCardContainer from '../components/idea-card/IdeaCardContainer';





const MainPage = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if screen size is mobile

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const [personalisedCards, setPersonalisedCards] = useState([]);
    const [topFranchiseCards, setTopFranchiseCards] = useState([]);
    const [franchiseCards, setFranchiseCards] = useState([]);
    const [partnershipCards, setPartnershipCards] = useState([]);
    const [dealerCards, setDealerCards] = useState([]);
    const [businessOnSaleCards, setBusinessOnSaleCards] = useState([]);
    const [featuredBusinessCards, setFeaturedBusinessCards] = useState([]);

    //const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchCardDetails();


                const tpersonalisedCards = response.data.filter(card => card.entityType === 'P');
                const ttopFranchiseCards = response.data.filter(card => card.entityType === 'T');
                const tfranchiseCards = response.data.filter(card => card.entityType === 'FRANCHISER');
                const tpartnershipCards = response.data.filter(card => card.entityType === 'P');
                const tdealerCards = response.data.filter(card => card.entityType === 'D');
                const tbusinessOnSaleCards = response.data.filter(card => card.entityType === 'S');
                const tfeaturedBusinessCards = response.data.filter(card => card.entityType === 'F');

                setPersonalisedCards(tpersonalisedCards);
                setTopFranchiseCards(ttopFranchiseCards);
                setFranchiseCards(tfranchiseCards);
                setPartnershipCards(tpartnershipCards);
                setDealerCards(tdealerCards);
                setBusinessOnSaleCards(tbusinessOnSaleCards);
                setFeaturedBusinessCards(tfeaturedBusinessCards)


            } catch (error) {
                // setError(error);
                console.error("Error fetching card details:", error);
            } finally {
                // setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <Box sx={{
            display: 'flex', flexDirection: 'column',
            // minHeight: '100vh' 
        }}>
            {/* Navbar */}

            {/* Banner */}
            {/* <Box sx={{ flexGrow: 1, backgroundColor: '#f5f5f5', py: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={10} md={8} lg={8}>
                        <Box
                            sx={{
                                backgroundColor: '#cfe8fc',
                                height: 200,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 24,
                                fontWeight: 'bold',
                                borderRadius: 1,
                            }}
                        >
                            Banner Section
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}

            {/* Main Content */}
            <Container maxWidth={'xxl'} disableGutters sx={{ flexGrow: 1 }}>

                <MainCard colour={'rgba(13, 38, 89, 3)'} isBanner={true}>



                    <Banner

                    />


                </MainCard>
                <MainCard >
                    <ExpertTalk />
                </MainCard>
                <MainCard colour={'#F1F5F9'}>
                    <InvestorBanner />
                </MainCard>
                <MainCard>
                    <CardContainer
                        title={"Personalised"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                        colour={'white'}

                    />
                </MainCard>
                <MainCard colour={'rgba(217, 179, 218, 0.2)'}>
                    <CardContainer
                        title={"Top Franchises"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                        cardData={franchiseCards} />

                </MainCard>








                <MainCard colour={'white'}>
                    <CardContainer
                        title={"Franchise Opportunities"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}

                        cardData={franchiseCards}

                    />

                </MainCard>





                <MainCard colour={'white'}>

                    <CardContainer
                        title="Partnership Opportunities"
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                    />

                </MainCard>
                <MainCard colour={'white'}>
                    <CardContainer
                        title={"Distributor/Dealership Opportunities"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                    />

                </MainCard>
                <MainCard colour={'white'}>
                    <CardContainer
                        title={"Business On Sale"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                    />

                </MainCard>
                <MainCard colour={'rgba(208, 33, 40, 0.05)'}>
                    <CardContainer
                        title={"Featured Business"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                    />


                </MainCard>
                <MainCard colour={'rgba(255, 252, 229, 0.5)'}>

                    <SuccessStories

                    />


                </MainCard>

                <MainCard colour={'#FFFFFF'}>

                    <IdeaCardContainer

                        title={"Ideas"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        titleBgColor={'rgba(237, 109, 168, 1)'}
                        contentBGColor={'rgba(255, 130, 188, 1)'}
                        content="This is a brief description of the innovative idea. It highlights the key points and provides an overview."
                        noOfCards={3}
                        defaultCard={3}
                    />

                    {/* <CardContainer
                        title={"Innovative Idea"}
                        description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                        noOfCards={3}
                        defaultCard={3}
                        cardData={[{ title: 'hello' }, { title: 'world' }]}

                    /> */}


                </MainCard>



            </Container>


        </Box>
    );
};

export default MainPage;
