

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFBuilding5, OFBulbIcon, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFFolderDetails, OFHouse, OFHouse4, OFIdeaIcon, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPercentageIcon, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';
import ProgressSteperBusinessCollaboration from '../progress/ProgressSteperBusinessCollaboration';




const BCFinancialInfoAndGrowthForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Business on Sales</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperBusinessCollaboration />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1"> Financial Information and Strategic Growth</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>



                    <Grid item xs={12} md={6}>
                        <Controller
                            name="annualRevenueProjectionYearOne"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Annual Revenue Projections (Year-1)  *"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.annualRevenueProjectionYearOne}
                                    helperText={errors.annualRevenueProjectionYearOne ? errors.annualRevenueProjectionYearOne.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="annualRevenueProjectionYearTwo"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Annual Revenue Projections (Year-2)"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.annualRevenueProjectionYearTwo}
                                    helperText={errors.annualRevenueProjectionYearTwo ? errors.annualRevenueProjectionYearTwo.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="annualRevenueProjectionYearThree"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Annual Revenue Projections (Year-3)"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.annualRevenueProjectionYearThree}
                                    helperText={errors.annualRevenueProjectionYearThree ? errors.annualRevenueProjectionYearThree.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={6}>
                        <Controller
                            name="profitMargin"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Profit Margin (Last year's profit margin, if applicable)  *"

                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.profitMargin}
                                    helperText={errors.profitMargin ? errors.profitMargin.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="currentValuation"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Current Valuation  *"
                                    required
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.currentValuation}
                                    helperText={errors.currentValuation ? errors.currentValuation.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="priorInvestment"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Investment History (Have you received any prior investments?) *"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.priorInvestment?.message}
                                    helperText={errors.priorInvestment ? errors.priorInvestment.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);

                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFRupeeIcon}
                                />
                            )}
                        />
                    </Grid>


















                    <Grid item xs={12} md={6}>
                        <Controller
                            name="investorName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label=" Investor name  *"

                                    fullWidth sicon={<OFUser />}
                                    variant="outlined"
                                    error={!!errors.investorName}
                                    helperText={errors.investorName ? errors.investorName.message : ''}
                                    placeholder={'Enter Investor Name'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="financialProjectionOneYear"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Financial Projections (Growth plan for the next 1 year)  *"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionOneYear}
                                    helperText={errors.financialProjectionOneYear ? errors.financialProjectionOneYear.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="financialProjectionSecondYear"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Financial Projections (Growth plan for 2nd year)  "

                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionSecondYear}
                                    helperText={errors.financialProjectionSecondYear ? errors.financialProjectionSecondYear.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="financialProjectionThirdYear"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Financial Projections (Growth plan for 3rd year)  "

                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.financialProjectionThirdYear}
                                    helperText={errors.financialProjectionThirdYear ? errors.financialProjectionThirdYear.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={6}>
                        <Controller
                            name="proposedPercentageShare"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Proposed Percentage Share  *"
                                    fullWidth sicon={<OFPercentageIcon />}
                                    variant="outlined"
                                    error={!!errors.proposedPercentageShare}
                                    helperText={errors.proposedPercentageShare ? errors.proposedPercentageShare.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="proposedInvestmentAmount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Proposed Investment amount  *"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.proposedInvestmentAmount}
                                    helperText={errors.proposedInvestmentAmount ? errors.proposedInvestmentAmount.message : ''}
                                    placeholder={'1,00,000'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="growthPlan"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Growth Plan (Outline your 3–5 year growth strategy)  *"
                                    fullWidth sicon={<OFBulbIcon />}
                                    variant="outlined"
                                    error={!!errors.growthPlan}
                                    helperText={errors.growthPlan ? errors.growthPlan.message : ''}
                                    placeholder={'Enter description'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="marketExpansion"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Market Expansion (If seeking help with entering new markets, provide details)  *"
                                    fullWidth sicon={<OFFolderDetails />}
                                    variant="outlined"
                                    error={!!errors.marketExpansion}
                                    helperText={errors.marketExpansion ? errors.marketExpansion.message : ''}
                                    placeholder={'Market Expansion'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="currentBusinessHurdles"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Current Business Hurdles *"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.currentBusinessHurdles}
                                    helperText={errors.currentBusinessHurdles ? errors.currentBusinessHurdles.message : ''}
                                    placeholder={'Enter the details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="developmentAreas"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Areas for Development (Leadership, strategy, scaling, etc.)  *"
                                    fullWidth sicon={<OFLocation />}
                                    variant="outlined"
                                    error={!!errors.developmentAreas}
                                    helperText={errors.developmentAreas ? errors.developmentAreas.message : ''}
                                    placeholder={'Development areas'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>










                </Grid>
            </Box>



        </>);
};

export default BCFinancialInfoAndGrowthForm;
