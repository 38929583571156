export const toProper = (message) => {
    if (!message) return "";


    message = message.replace(/_/g, " ");

    return message
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
};



export const formatInvestment = (value) => {
    if (value >= 10000000) {
        return `${(value / 10000000).toFixed(1)} C`;
    } else if (value >= 100000) {
        return `${(value / 100000).toFixed(1)} L`;
    } else if (value >= 1000) {
        return `${(value / 1000).toFixed(1)} K`;
    } else {
        return value?.toString();
    }
};


// bulletStyles.js
export const bulletStyle = {
    fontWeight: 'bold',
    position: 'relative',
    paddingLeft: '1rem',
    '&::before': {
        content: '""',
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#000',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
};

export function extractParts(ownerMobileNo) {
    if (ownerMobileNo != null) {

        const [countryCode, phoneNumber] = ownerMobileNo.split('-');


        return {
            countryCode: countryCode || null,
            phoneNumber: phoneNumber || null
        };
    } else {

        return {
            countryCode: null,
            phoneNumber: null
        };
    }
}
