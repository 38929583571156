// import { atom } from 'recoil';

// // Atom for authentication status
// export const isLoggedInState = atom({
//     key: 'isLoggedInState',
//     default: false,
// });

// export const perInfoState = atom({
//     key: 'perInfoState',
//     default: null,
// });

// export const businessInfoState = atom({
//     key: 'businessInfoState',
//     default: null,
// });

// export const propertyInfoState = atom({
//     key: 'propertyInfoState',
//     default: null,
// });

// export const appearanceInfoState = atom({
//     key: 'appearanceInfoState',
//     default: null,
// });

// export const paymentInfoState = atom({
//     key: 'paymentInfoState',
//     default: null,
// });

import { atom } from 'recoil';


// const getFromLocalStorage = (key, defaultValue) => {
//     const savedState = localStorage.getItem(key);
//     return savedState ? JSON.parse(savedState) : defaultValue;
// };


// const saveToLocalStorage = (key, value) => {
//     localStorage.setItem(key, JSON.stringify(value));
// };

const secretKey = 'srp-soft';


const encryptData = (data) => {
    const text = JSON.stringify(data);
    const encrypted = btoa(unescape(encodeURIComponent(text)));
    return encrypted;
};


const decryptData = (encryptedData) => {
    try {
        const text = decodeURIComponent(escape(atob(encryptedData)));
        return JSON.parse(text);
    } catch (error) {
        console.error('Decryption failed', error);
        return null;
    }
};


const getFromLocalStorage = (key, defaultValue) => {
    const savedState = localStorage.getItem(key);
    return savedState ? decryptData(savedState) : defaultValue;
};


const saveToLocalStorage = (key, value) => {
    const encryptedValue = encryptData(value);
    localStorage.setItem(key, encryptedValue);
};


export const isLoggedInState = atom({
    key: 'isLoggedInState',
    default: getFromLocalStorage('isLoggedInState', false),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('isLoggedInState', newValue);
            });
        },
    ],
});

export const perInfoState = atom({
    key: 'perInfoState',
    default: getFromLocalStorage('perInfoState', null),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('perInfoState', newValue);
            });
        },
    ],
});

export const businessInfoState = atom({
    key: 'businessInfoState',
    default: getFromLocalStorage('businessInfoState', null),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('businessInfoState', newValue);
            });
        },
    ],
});

export const propertyInfoState = atom({
    key: 'propertyInfoState',
    default: getFromLocalStorage('propertyInfoState', null),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('propertyInfoState', newValue);
            });
        },
    ],
});

export const appearanceInfoState = atom({
    key: 'appearanceInfoState',
    default: getFromLocalStorage('appearanceInfoState', null),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('appearanceInfoState', newValue);
            });
        },
    ],
});

export const paymentInfoState = atom({
    key: 'paymentInfoState',
    default: getFromLocalStorage('paymentInfoState', null),
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                saveToLocalStorage('paymentInfoState', newValue);
            });
        },
    ],
});
