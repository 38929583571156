import React, { useState } from 'react';
import { Button, Typography, Stack, Dialog, DialogContent, Box, Grid, IconButton, DialogActions, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PersonPinCircleOutlined } from '@mui/icons-material';
import { OFCloseButton, OFInvestor } from '../../icons/CustomIcon';


import CloseIcon from '@mui/icons-material/Close';
import SignUp from '../../pages/signup/SignUp';
import { oneFranHubtheme } from '../../theme/theme';
import { DialogCommonHooks } from '../../helpers/modal/DialogCommonHooks';


const Popup = ({ handleClose }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => setOpenDialog(true);
    //const handleClose = () => setOpenDialog(false);

    return (


        // <Dialog
        //     open
        //     onClose={handleClose}
        //     PaperProps={{
        //         sx: {
        //             borderRadius: 2,
        //             background: 'transparent',
        //             boxShadow: 'none',
        //             //  minWidth: isMobile ? '100%' : '25%',
        //             minHeight: 'auto'
        //         }
        //     }}
        // >
        //     <Box
        //         display="flex"
        //         justifyContent="center"
        //         alignItems="center"
        //         position="relative"
        //         p={2}
        //     >
        //         <IconButton
        //             onClick={handleClose}
        //             sx={{
        //                 position: 'absolute',
        //                 right: 0,
        //                 top: 0
        //             }}
        //         >
        //             <CloseIcon />
        //         </IconButton>
        //     </Box>
        //     <DialogActions sx={{ justifyContent: 'center' }}>
        //         <Button onClick={handleClose} color="primary">
        //             {/* <img src={OFCloseButton} width="82px" height="auto" alt="Close" /> */}
        //             <OFCloseButton width="82px" height="auto" />
        //         </Button>
        //     </DialogActions>

        //     <DialogContent sx={{ background: 'white', borderRadius: 5, paddingTop: 0 }}>
        //         <SignUp onSuccess={handleClose} handleClose={handleClose} />  {/*  SIGNIN */}
        //     </DialogContent>

        // </Dialog>

        <DialogCommonHooks
            show={openDialog}
            handleClose={handleClose}
            body={<SignUp onSuccess={handleClose} />} // Replace this with your form component
        />
    );
};

export default Popup;
