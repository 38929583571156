
import * as yup from 'yup';


export const dropdownValidationMulti = yup.object().shape({
    id: yup.string().required('City is required'),
    name: yup.string().required('City is required'),
}).required('Sector is required');

// Address

export const countryValidation = yup.object().shape({
    name: yup.string().required('Country is required'),
}).nullable().required('Required');

export const stateValidation = yup.object().shape({
    name: yup.string().required('State is required'),
}).nullable().required('Required');

export const cityValidation = yup.object().shape({
    name: yup.string().required('City is required'),
}).nullable().required('Required');

export const pincodeValidation = yup
    .string()
    .required('PIN Code is required')
    .length(6, 'PIN Code must be 6 digits') // Enforces exactly 6 digits
    .matches(/^\d+$/, 'PIN Code must contain only digits'); // Ensures only digits are allowed

// Industry


export const industryValidation = yup.object().shape({
    name: yup.string().required('Industry is required'),
}).required('Industry is required');

export const sectorValidation = yup.object().shape({
    name: yup.string().required('Sector is required'),
}).required('Sector is required');


// Contact

export const websiteValidation = yup.string()
    .matches(
        /^(www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
        'Invalid URL. Must start with "www."'
    )
    .required('Website Link is required');


export const emailValidation = (message = 'Email is required') =>
    yup.string().email('Invalid email format').required(message);

// export const textValidation = ({
//     message = 'This field is required',
//     min = 1,
//     max = 255,
//     minMessage = `Must be at least ${min} characters`,
//     maxMessage = `Must be no more than ${max} characters`,
// } = {}) =>
//     yup
//         .string()
//         .required(message)
//         .min(min, minMessage)
//         .max(max, maxMessage);





export const textValidation = ({
    message = 'Required',
    min = 1,
    max = 255,
    minMessage = `Must be at least ${min} characters`,
    maxMessage = `Must be no more than ${max} characters`,
} = {}) =>
    yup
        .string()
        .required(message)
        .min(min, minMessage)
        .max(max, maxMessage);



// companyName: textValidation({ message: 'Company Name is required', min: 3, max: 100 }),



export const countryCodeValidation = yup
    .string()
    .required('Country code is required');

// Validation for phone number
// export const phoneNumberValidation = yup
//     .string()
//     .required('Phone number is required');


export const phoneValidation = yup.object().shape({
    countryCode: yup.string().required('Country code is required'),
    phoneNumber: yup
        .string()
        .required('Phone number is required')
        .length(10, 'Phone number must be 10 digits'), // Enforces exactly 10 digits
});


//CustomDropDown
export const dropdownValidation = yup.object().shape({
    name: yup.string().required('Required'),


});


export const multiSelectCheckBoxValidation = (fieldName) =>
    yup.array()
        .of(yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required()
        }))
        .min(1, `${fieldName} requires at least one selection.`);



export const numericValidation = yup.number()
    .typeError('Must be a number')
    .integer('Only whole numbers (integers) are allowed')
    .test(
        'max-value',
        'Max value reached (Up to 10 digits allowed)',
        value => /^\d{1,10}$/.test(value)
    )
    .required('Required');


export const currencyValidation = yup.number()
    .typeError('Must be a number')
    .test(
        'is-decimal',
        'Max value reached ( Two decimal value allowed)',
        value => /^\d{1,10}(\.\d{1,2})?$/.test(value)
    )
    .required('Required');

export const scoreValidation = yup.number()
    .typeError('Must be a number')
    .min(1, 'Value must be at least 1')
    .max(500, 'Value cannot exceed 500')
    .nullable()
    .notRequired();
//.required('Required');

// export const scoreValidationOptional = yup.number()
//     .typeError('Must be a number')
//     .min(1, 'Value must be at least 1')
//     .max(500, 'Value cannot exceed 500')
//     .nullable()  // Allows the field to be empty
//     .notRequired();  // Makes it optional


export const scoreValidationOptional = yup.number()
    .transform((value, originalValue) => {
        // If the original value is an empty string, transform it to undefined
        return originalValue === '' ? undefined : value;
    })
    .typeError('Must be a number')  // Show this error if a non-number is entered
    .min(1, 'Value must be at least 1')
    .max(500, 'Value cannot exceed 500')
    .nullable()  // Allows the field to be null or empty
    .notRequired();  // Makes the field optional


export const booleanValidation = (fieldLabel) =>
    yup
        .boolean()
        .oneOf([true], `You must accept the ${fieldLabel}`)
        .required(`You must accept the ${fieldLabel}`);

export const booleanValidationNormal = (fieldLabel) =>
    yup
        .boolean()
        .required(`Please select ${fieldLabel}`);


// CustomCheckBox
export const royaltyFeeValidation = yup.object().shape({
    type: yup
        .string()
        .oneOf(['percentage', 'fixedAmount'], 'Invalid fee type')
        .required('Fee type is required'),
    value: yup
        .number()
        .typeError('Value must be a number')
        .positive('Value must be a positive number')
        .required('Fee value is required')
});