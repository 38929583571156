
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
    Button,
    IconButton,
    Box,
    Grid,
    Typography,
    TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";

import { API } from "../../axios/api-path";
import { postApiCall } from "../../axios/axios-methods";
import { AuthContext } from "../../context/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { toastError } from "../../utils/toast-notify";

// Define the validation schema using Yup
const validationSchema = Yup.object({
    username: Yup.string().required('Username or email address is required'),
    password: Yup.string().required('Password is required'),
});

export default function LoginForm({ onSuccess }) {

    const { login } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();
    const website = location?.state?.website;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            script.defer = true;
            script.onload = initializeGoogleSignIn;
            document.body.appendChild(script);
        };

        const initializeGoogleSignIn = () => {
            if (window.google) {
                window.google.accounts.id.initialize({
                    client_id: "738217162850-4gmkqhnjuoh23aevgig7ek7k7mndbj8v.apps.googleusercontent.com",
                    callback: handleCredentialResponse,
                });

                window.google.accounts.id.renderButton(
                    document.getElementById("buttonDiv"),
                    { theme: "outline", size: "large" }
                );

                window.google.accounts.id.prompt();
            }
        };

        loadGoogleScript();
    }, []);

    const handleCredentialResponse = (response) => {
        const token = response.credential;

        const params = new URLSearchParams();
        params.append('credential', token);

        fetch('http://onefranhub.com/api/auth/verify-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params.toString()
        })
            .then(response => {
                // console.log('Fetch Response:', response); 
                return response.json();
            })
            .then(data => {
                // console.log('API Response Data:', data); 
                if (data.status === "success") {
                    handleGoogleLogin(data);
                } else {
                    console.log('Authentication Google login failed');
                }
            })
            .catch(error => console.error('Error:', error));
    };

    const validateForm = async () => {
        try {
            await validationSchema.validate({ username, password }, { abortEarly: false });
            setErrors({});
            return true;
        } catch (error) {
            const newErrors = error.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = await validateForm();
        if (isValid) {
            // Handle form submission here
            // console.log("Form values:", { username, password });
            const apidata = {
                username: username,
                password: password
            };


            const path = API.signin();

            try {
                await postApiCall({
                    path,
                    data: apidata,
                    onSuccess: (responseData) => {
                        handleLogin(responseData);
                    },
                    onError: (error) => {
                        console.error('Error:', error.message);
                        toastError(error)
                    }
                });
            } catch (error) {
                console.error('Unexpected error:', error);
                // Handle unexpected errors here
            }
        }
    };

    const handleLogin = (responseData) => {
        console.log("Successfully logged in using credentials");
        login(responseData);
        console.log(" New implemntation login called ")
        navigate('/');
    };

    const handleGoogleLogin = (responseData) => {
        console.log('Successfully logged in using Google');
        login(responseData);
        navigate('/');
    };

    const handleLinkClick = (event) => {
        event.preventDefault();

        // Additional logic here
        navigate('/'); // Manually trigger navigation
    };

    return (
        // <>
        //     <ToastContainer />
        //     <form onSubmit={handleSubmit} noValidate >
        //         <Box
        //             px={{ xs: 2, md: 3 }}
        //             pb={{ xs: 2, md: 3 }}
        //             minHeight="40vh"
        //             justifyContent="center"
        //             flexDirection="column"
        //             display="flex"
        //             marginTop={4}
        //         >
        //             <Box>
        //                 <Grid container justifyContent="space-between">
        //                     <Grid item>
        //                         <Box
        //                             sx={{
        //                                 width: '15.25rem',
        //                                 position: 'relative',
        //                                 textAlign: 'left',
        //                                 display: 'inline-block',
        //                                 fontSize: '1.375rem',
        //                                 color: '#000',
        //                                 fontFamily: 'Outfit',
        //                             }}
        //                         >
        //                             <Typography component="span" sx={{ fontWeight: 600 }}>
        //                                 Welcome to
        //                             </Typography>
        //                             <Typography component="span" sx={{ fontSize: '1.313rem', fontFamily: 'Poppins' }}>
        //                                 {` `}
        //                             </Typography>
        //                             <Typography component="span" sx={{ fontWeight: 600, color: '#0d51d9' }}>
        //                                 Onefranhub
        //                             </Typography>
        //                         </Box>
        //                     </Grid>
        //                     <Grid item sx={{ textAlign: "right" }}>
        //                         <Typography>
        //                             Register &nbsp;
        //                             <Link
        //                                 to={API.signup}
        //                                 style={{ color: "#0daee2" }}
        //                                 onClick={handleLinkClick}
        //                             >
        //                                 Here
        //                             </Link>
        //                         </Typography>
        //                     </Grid>
        //                 </Grid>
        //             </Box>
        //             <Box mt={3}>
        //                 <Typography fontWeight="bold" fontSize="2.75rem">
        //                     Sign In
        //                 </Typography>
        //             </Box>

        //             <Box id="buttonDiv" mb={6} mt={2} width={'368px'}>
        //                 {/* Google Sign-In button will be rendered here */}
        //             </Box>

        //             <Box mt={1} mb={4} >
        //                 <Box mb={2}>
        //                     <Typography>
        //                         Enter your username or email address
        //                     </Typography>
        //                 </Box>

        //                 <TextField
        //                     required
        //                     value={username}
        //                     onChange={(e) => setUsername(e.target.value)}
        //                     name="username"
        //                     placeholder="Username or email address"
        //                     fullWidth
        //                     error={!!errors.username}
        //                     helperText={errors.username}
        //                     FormHelperTextProps={{
        //                         sx: {
        //                             fontSize: '0.9rem',
        //                             color: 'red',
        //                         },
        //                     }}
        //                 />
        //             </Box>
        //             <Box >
        //                 <Box mb={0} mt={0} p={0}>
        //                     <Box mb={2}>
        //                         <Typography>
        //                             Enter your Password
        //                         </Typography>
        //                     </Box>

        //                     <TextField
        //                         required
        //                         type={showPassword ? "text" : "password"}
        //                         value={password}
        //                         onChange={(e) => setPassword(e.target.value)}
        //                         name="password"
        //                         placeholder="Password"
        //                         fullWidth
        //                         error={!!errors.password}
        //                         helperText={errors.password}
        //                         FormHelperTextProps={{
        //                             sx: {
        //                                 fontSize: '0.9rem',
        //                                 color: 'red',
        //                             },
        //                         }}
        //                         InputProps={{
        //                             endAdornment: (
        //                                 <IconButton
        //                                     aria-label="toggle password visibility"
        //                                     onClick={() => setShowPassword(!showPassword)}
        //                                     edge="end"
        //                                 >
        //                                     {showPassword ? <VisibilityOff /> : <Visibility />}
        //                                 </IconButton>
        //                             ),
        //                         }}
        //                     />
        //                 </Box>
        //             </Box>
        //             <Box mb={2} >
        //                 <Box mt={1} mb={3} display="flex" justifyContent="center">
        //                     <Link
        //                         to="/forget"
        //                         style={{
        //                             color: "#0daee2",
        //                             fontFamily: "Outfit-Regular",
        //                             marginBottom: "1rem",
        //                             fontSize: "1rem"
        //                         }}
        //                     >
        //                         Forgot Password
        //                     </Link>
        //                 </Box>
        //                 <Button
        //                     type="submit"
        //                     variant="contained"
        //                     sx={{
        //                         background: 'linear-gradient(90deg, #042f85, #0d51d9)',
        //                         color: '#FFFFFF',
        //                         '&:hover': {
        //                             background: 'linear-gradient(90deg, #042f85, #0d51d9)',
        //                         },
        //                     }}
        //                     size="large"
        //                     fullWidth
        //                 >
        //                     Sign In
        //                 </Button>
        //             </Box>
        //         </Box>
        //     </form>
        // </>
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
                <Box
                    px={{ xs: 2, md: 3 }}
                    pb={{ xs: 2, md: 3 }}
                    // minHeight="40vh"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginTop={1}
                    width="100%"
                >
                    {/* <Box width="100%">
                        <Grid container justifyContent="space-between" alignItems="center" width="100%">
                            <Grid item xs={12} md="auto">
                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '15.25rem' },
                                        textAlign: { xs: 'center', md: 'left' },

                                        color: '#000',
                                        fontFamily: 'Outfit',
                                        marginBottom: { xs: 2, md: 0 },
                                    }}
                                >
                                    <Typography variant="Body" component="span" >
                                        Welcome to
                                    </Typography>
                                    <Typography variant="Body" component="span" >
                                        {` `}
                                    </Typography>
                                    <Typography variant="Body" component="span" sx={{ fontWeight: 600, color: '#0D2659' }}>
                                        Onefranhub
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                                <Typography variant="Body" sx={{ color: '#BDBDBD' }}>
                                    Register


                                    &nbsp;
                                    <Link
                                        to={API.signup}
                                        style={{ color: '#0daee2' }}
                                        onClick={handleLinkClick}
                                    >
                                        Here
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box> */}
                    <Box width="100%">
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            width="100%"
                            sx={{
                                // Ensure proper alignment on tablets and mobile
                                justifyContent: { xs: 'center', sm: 'space-between' }, // 'sm' for tablets and larger
                                textAlign: { xs: 'center', sm: 'left' }
                            }}
                        >
                            {/* Left Section - Welcome Text */}
                            <Grid item xs={12} sm="auto">
                                <Box
                                    sx={{
                                        width: { xs: '100%', sm: '15.25rem' },
                                        color: '#000',
                                        fontFamily: 'Outfit',
                                        marginBottom: { xs: 2, sm: 0 },
                                        textAlign: { xs: 'center', sm: 'left' },
                                    }}
                                >
                                    <Typography variant="body1" component="span">
                                        Welcome to
                                    </Typography>
                                    <Typography variant="body1" component="span">
                                        {` `}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        sx={{ fontWeight: 600, color: '#0D2659' }}
                                    >
                                        Onefranhub
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Right Section - Register Link */}
                            <Grid
                                item
                                xs={12}
                                sm="auto"
                                sx={{
                                    textAlign: { xs: 'center', sm: 'right' },
                                    display: 'flex',
                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                }}
                            >
                                <Typography variant="body1" sx={{ color: '#BDBDBD' }}>
                                    Register&nbsp;
                                    {/* <Link
                                        to={API.signup} // Use the correct path or URL here
                                        style={{ color: '#0daee2', textDecoration: 'none' }}
                                        onClick={handleLinkClick}
                                    >
                                        Here
                                    </Link> */}
                                    <span
                                        style={{ color: "#0daee2", cursor: "pointer", textDecoration: 'none' }}
                                    // onClick={handleRegister}
                                    >
                                        Sign up
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={3} width="100%" textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography variant="h3" fontWeight="bold" sx={{ color: '#0D2659' }} >
                            Sign in
                        </Typography>
                    </Box>

                    <Box id="buttonDiv" mb={3} mt={2} width="100%">
                        {/* Google Sign-In button will be rendered here */}
                    </Box>

                    <Box mt={0} mb={2} width="100%">
                        <Box mb={2}>
                            <Typography>Enter your username or email address</Typography>
                        </Box>

                        <TextField
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            name="username"
                            placeholder="Username or email address"
                            fullWidth
                            error={!!errors.username}
                            helperText={errors.username}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '0.9rem',
                                    color: 'red',
                                },
                            }}
                            sx={{ maxWidth: '100%' }}
                        />
                    </Box>

                    <Box width="100%">
                        <Box mb={0} mt={0} p={0}>
                            <Box mb={2}>
                                <Typography>Enter your Password</Typography>
                            </Box>

                            <TextField
                                required
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                name="password"
                                placeholder="Password"
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password}
                                FormHelperTextProps={{
                                    sx: {
                                        fontSize: '0.9rem',
                                        color: 'red',
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                                sx={{ maxWidth: '100%' }}
                            />
                        </Box>
                    </Box>

                    <Box mb={0} width="100%">
                        <Box mt={1} mb={2} display="flex" justifyContent="center">
                            <Link
                                to="/forget"
                                style={{
                                    color: '#0daee2',
                                    fontFamily: 'Outfit-Regular',
                                    marginBottom: '1rem',
                                    fontSize: '1rem',
                                }}
                            >
                                Forgot Password
                            </Link>
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                color: '#FFFFFF',
                                '&:hover': {
                                    background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                },
                            }}
                            size="large"
                            fullWidth
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </form>
        </>

    );
}
