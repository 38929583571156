

// import React, { useEffect, useState } from 'react';
// import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
// import { useForm, Controller } from 'react-hook-form';
// import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { oneFranHubtheme } from '../../../theme/theme';
// import { API } from '../../../axios/api-path';

// import { postApiCallWithCred } from '../../../axios/axios-methods';
// import { toastError, toastSuccess } from '../../../utils/toast-notify';
// import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
// import { dropdownValidation, dropdownValidationMulti, industryValidation, numericValidation, sectorValidation, textValidation, websiteValidation } from '../../../utils/validations';
// import DealerMarketingInfoForm from './DealerMarketingInfoForm.js';
// import CustomButton from '../../../helpers/CustomButton.js';





// const DealerMarketingInfo = ({ isDashboardMode }) => {
//     const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
//     const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

//     const navigate = useNavigate();
//     const location = useLocation();

//     const [formData, setFormData] = useState(null);
//     const [isFormData, setIsFormData] = useState(false);
//     // const [outletStates, setOutletStates] = useState([])

//     const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

//     useEffect(() => {
//         fetchCountries(setCountries);
//         fetchIndustries(setIndustries);
//         fetchRange(setRanges);
//         const fetchData = async () => {
//             const path = API.getDealerInfo();

//             try {
//                 const response = await fetchFormInfo(path);

//                 if (response.data.marketingStatus === 'COMPLETE') {
//                     setFormData(response.data.marketing);

//                     setIsFormData(true);
//                 } else {
//                     setIsFormData(false);
//                 }
//             } catch (err) {
//                 // Handle error
//             }
//         };

//         fetchData();
//     }, []);



//     const validationSchema = yup.object({
//         "companyName": textValidation({ message: 'Rrequired' }),

//         "employeeCount": numericValidation,
//         "companyType": dropdownValidation,
//         "businessType": dropdownValidation,
//         "industry": industryValidation,
//         "industrySector": sectorValidation,
//         "businessWebsite": websiteValidation,
//         "facilities": textValidation({ message: 'Rrequired' }),
//         "customerBase": dropdownValidation,
//         "businessSummary": textValidation({ message: 'Rrequired' }),
//         "establishmentYear": textValidation({ message: 'Rrequired' }),


//     });




//     const {
//         control,
//         handleSubmit,
//         reset,
//         watch,
//         setValue, getValues,
//         formState: { errors },
//     } = useForm({
//         defaultValues: {




//         },
//         resolver: yupResolver(validationSchema),
//     });


//     const {
//         industry,
//         country,
//         city,
//         state,
//         loanSupportRequired,
//     }
//         = watch({ control });


//     useEffect(() => {
//         console.log(" HII" + loanSupportRequired)
//         if (loanSupportRequired === 'No') {
//             setValue("acceptTermAndConditions", null);
//             setValue("desiredLoanAmount", null);
//             setValue("preferredLoanTerm", null);
//             setValue("monthlyIncome", null);
//             setValue("existingDebt", null);
//             setValue("creditScore", null);
//             setValue("specificRequirementOnLoan", null);
//             setValue("loanAgainstProperty", null);
//             setValue("typeOfProperty", null);
//             setValue("propertyValue", null);
//             setValue("propertyArea", null);
//             setValue("doYouWantSaySomething", null);

//         }
//     }, [loanSupportRequired, setValue]);

//     useEffect(() => {

//         if (country) {
//             fetchStates(setStates, country.id);

//         }
//     }, [country, setValue]);
//     useEffect(() => {

//         if (state) {
//             fetchCities(setCities, state.id);

//         }
//     }, [state, setValue]);

//     useEffect(() => {

//         if (industry) {
//             fetchSectors(setIndustrySectors, industry.id);

//         }
//     }, [industry, setValue]);

//     const [countries, setCountries] = useState([]);
//     const [states, setStates] = useState([]);
//     const [cities, setCities] = useState([]);
//     const [industries, setIndustries] = useState([]);
//     const [industrySectors, setIndustrySectors] = useState([]);
//     const [ranges, setRanges] = useState([]);

//     const [selectedCountry, setSelectedCountry] = useState('');
//     const [selectedState, setSelectedState] = useState('');
//     const [selectedCity, setSelectedCity] = useState('');
//     const [selectedIndustry, setSelectedIndustry] = useState('');
//     const [selectedSector, setSelectedSector] = useState('');
//     const [selectedRange, setSelectedRange] = useState('');

//     useEffect(() => {
//         if (selectedCountry) {
//             setValue('state', '');
//             setValue('city', '');
//             setStates([]);
//             setCities([]);
//         }
//     }, [selectedCountry, setValue]);

//     useEffect(() => {
//         if (selectedState) {
//             setValue('city', '');
//             setCities([]);
//         }
//     }, [selectedState, setValue]);



//     useEffect(() => {

//         if (selectedCountry) {

//             fetchStates(setStates, selectedCountry);
//         }
//     }, [selectedCountry]);

//     useEffect(() => {
//         if (selectedState) {
//             fetchCities(setCities, selectedState);
//         }
//     }, [selectedState]);

//     useEffect(() => {
//         if (selectedIndustry) {
//             fetchSectors(setIndustrySectors, selectedIndustry);
//         }
//     }, [selectedIndustry]);

//     function extractParts(ownerMobileNo) {
//         if (ownerMobileNo != null) {

//             const [countryCode, phoneNumber] = ownerMobileNo.split('-');


//             return {
//                 countryCode: countryCode || null,
//                 phoneNumber: phoneNumber || null
//             };
//         } else {

//             return {
//                 countryCode: null,
//                 phoneNumber: null
//             };
//         }
//     }


//     useEffect(() => {
//         if (formData) {

//             reset({
//                 "companyName": formData?.companyName,
//                 "establishmentYear": formData?.establishmentYear,
//                 "employeeCount": formData?.employeeCount,
//                 "companyType": formData?.companyType,
//                 "businessType": formData?.businessType,
//                 "industry": formData?.industry,
//                 "industrySector": formData?.industrySector,
//                 "businessWebsite": formData?.businessWebsite,
//                 "facilities": formData?.facilities,
//                 "customerBase": formData?.customerBase,
//                 "businessSummary": formData?.businessSummary


//             });
//         }
//     }, [formData, reset]);

//     const onSubmit = async (data) => {

//         const apiCallData = {



//             "companyName": data?.companyName,
//             "establishmentYear": data?.establishmentYear,
//             "employeeCount": data?.employeeCount,
//             "companyType": data?.companyType,
//             "businessType": data?.businessType,
//             "industry": data?.industry,
//             "industrySector": data?.industrySector,
//             "businessWebsite": data?.businessWebsite,
//             "facilities": data?.facilities,
//             "customerBase": data?.customerBase,
//             "businessSummary": data?.businessSummary









//         };


//         const path = API.signupDealerMarketingInfo();
//         try {

//             console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
//             await postApiCallWithCred({
//                 data: apiCallData,
//                 path: path,
//                 onSuccess: (res) => {

//                     if (isDashboardMode) {
//                         setIsEditing(false)

//                     }
//                     else {
//                         handleNext();


//                     }



//                 },
//                 onError: (error) => {
//                     toastError(error);
//                 }
//             });
//         } catch (error) {
//             console.error('Unexpected error:', error);
//         }
//     };

//     const handleNext = () => {
//         navigate(location.pathname.replace(/[^/]*$/, '4'));
//     };

//     const handleBack = () => {
//         navigate(location.pathname.replace(/[^/]*$/, '2'));
//     };


//     return (

//         <>
//             <ToastContainer

//             />

//             <Paper
//                 elevation={2}
//                 sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     gap: 2,
//                     borderRadius: 4,
//                     paddingBottom: 4,
//                 }}
//             >
//                 <DealerMarketingInfoForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

//                     countries={countries}
//                     cities={cities}
//                     states={states}
//                     industries={industries}
//                     industrySectors={industrySectors}
//                     ranges={ranges}
//                     setSelectedCity={setSelectedCity}
//                     setSelectedCountry={setSelectedCountry}
//                     setSelectedIndustry={setSelectedIndustry}
//                     setSelectedSector={setSelectedSector}
//                     setSelectedState={setSelectedState}
//                     isDashboardMode={isDashboardMode}
//                     isEditing={isEditing}
//                     setIsEditing={setIsEditing}
//                     loanSupportRequired={loanSupportRequired}

//                 />


//                 {!isDashboardMode ? (
//                     <Stack
//                         direction={{ xs: 'column', sm: 'row' }}
//                         spacing={2}
//                         justifyContent="center"
//                         alignItems="center"
//                         sx={{ width: '100%', p: 2 }}
//                     >
//                         <CustomButton
//                             type="button"
//                             width={{ xs: '75%', sm: '10rem' }}
//                             variant="contained"
//                             label="Back"
//                             onClick={handleBack}
//                         />

//                         <CustomButton
//                             type="submit"
//                             width={{ xs: '75%', sm: '14rem' }}
//                             variant="contained"
//                             label={"Save and Next"}
//                             onClick={handleSubmit(onSubmit)}
//                         />
//                     </Stack>
//                 ) : (
//                     isEditing && (
//                         <Stack
//                             direction={{ xs: 'column', sm: 'row' }}
//                             spacing={2}
//                             justifyContent="center"
//                             alignItems="center"
//                             sx={{ width: '100%', p: 2 }}
//                         >
//                             <CustomButton
//                                 type="submit"
//                                 width={{ xs: '75%', sm: '14rem' }}
//                                 variant="contained"
//                                 label="Update"
//                                 onClick={handleSubmit(onSubmit)}
//                             />
//                         </Stack>
//                     )
//                 )}



//             </Paper>

//         </>
//     )
// }

// export default DealerMarketingInfo



import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';

import { cityValidation, countryValidation, emailValidation, phoneValidation, pincodeValidation, stateValidation, textValidation, websiteValidation } from '../../../utils/validations';
import DealerMarketingInfoForm from './DealerMarketingInfoForm';


const DealerMarketingInfo = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);

        const fetchData = async () => {
            const path = API.getDealerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.businessInfoStatus === 'COMPLETE') {
                    setFormData(response.data.businessInfo);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({




        country: countryValidation,
        state: stateValidation,
        city: cityValidation,
        postalCode: pincodeValidation,
        street: textValidation({ message: 'Rrequired' }),


        "companyName": textValidation({ message: 'Rrequired' }),
        "ownerName": textValidation({ message: 'Rrequired' }),
        "ownerEmail": emailValidation('Required'),
        // "ownerAlternateEmail": emailValidation('Required'),
        "companyWebsiteLink": websiteValidation,

        ownerMobileNo: phoneValidation,
        ownerAlternateMobileNo: phoneValidation,








    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });




    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);



    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');



    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);



    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({

                "companyName": formData?.companyName,
                "ownerName": formData?.ownerName,
                "ownerEmail": formData?.ownerEmail,
                "ownerAlternateEmail": formData?.ownerAlternateEmail,
                "companyWebsiteLink": formData?.companyWebsiteLink,

                "street": formData?.address?.street,
                "city": formData?.address?.city,
                "state": formData?.address?.state,
                "country": formData?.address?.country,
                "postalCode": formData?.address?.postalCode,



                ownerMobileNo: {
                    countryCode: extractParts(formData?.ownerMobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.ownerMobileNo).phoneNumber || '',
                },
                ownerAlternateMobileNo: {
                    countryCode: extractParts(formData?.ownerAlternateMobileNo).countryCode || '',
                    phoneNumber: extractParts(formData?.ownerAlternateMobileNo).phoneNumber || '',
                },




            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {


            "companyName": data?.companyName,
            "ownerName": data?.ownerName,
            "ownerEmail": data?.ownerEmail,
            "ownerAlternateEmail": data?.ownerAlternateEmail,
            "companyWebsiteLink": data?.companyWebsiteLink,

            "ownerAlternateMobileNo": data?.ownerAlternateMobileNo?.countryCode + "-" + data.ownerAlternateMobileNo?.phoneNumber,
            "ownerMobileNo": data?.ownerMobileNo?.countryCode + "-" + data.ownerMobileNo?.phoneNumber,


            address: {
                street: data?.street,
                city: { id: parseInt(data?.city?.id, 10) },
                state: { id: parseInt(data?.state?.id, 10) },
                postalCode: data?.postalCode,
                country: { id: parseInt(data?.country?.id, 10) }
            },


        };


        const path = API.signupDealerCompanyInfo();
        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '1'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <DealerMarketingInfoForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}

                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}


                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}


                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default DealerMarketingInfo