

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFCalendar, OFCastle, OFComment, OFCompany, OFCountry, OFDescription, OFHouse, OFHouse1, OFIndustry, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';




const LoanPropertyForm = ({ title, control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,



}) => {


    const [loanAgainstProperty, setLoanAgainstProperty] = useState(false)





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];




    // const outletStates = states.map(state => ({
    //     id: state.id,
    //     name: state.label
    // }));


    return (
        <>
            <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                <Typography variant="h5">{title}</Typography>
            </Box>



            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                {/* <Box marginBottom={1} marginTop={1}>
                    <Typography variant="TITLE1">Need a space</Typography>

                </Box> */}


                <Grid container spacing={4}>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="fullName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Full Name"
                                    fullWidth sicon={<OFUser />}
                                    variant="outlined"
                                    error={!!errors.fullName}
                                    helperText={errors.fullName ? errors.fullName.message : ''}
                                    placeholder={'Full Name  *'}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="emailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Email Id "
                                    fullWidth sicon={<OFMailIcon />}
                                    variant="outlined"
                                    error={!!errors.emailId}
                                    helperText={errors.emailId ? errors.emailId.message : ''}
                                    placeholder={'Email Id  *'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>


                        <Controller
                            name="mobileNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Mobile No. "
                                    fullWidth
                                    required
                                    countryCodeOptions={countryCodeOptions}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={errors.mobileNo?.phoneNumber?.message || ''}
                                    error={!!errors.mobileNo}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="residentialAddress"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Residential Address "
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.residentialAddress}
                                    helperText={errors.residentialAddress ? errors.residentialAddress.message : ''}
                                    placeholder={'Residential Address  *'}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="residentialAddress"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Residential Address  *"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.residentialAddress?.name}
                                    helperText={errors.residentialAddress?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid> */}




                    {/* <Grid item xs={12} md={6}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry}
                                    helperText={errors.industry ? errors.industry.message : ''}
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} md={6}>

                        <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.sector}
                                    helperText={errors.sector ? errors.sector.message : ''}
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="location"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Location "
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.location}
                                    helperText={errors.location ? errors.location.message : ''}
                                    placeholder={'Location  *'}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />}
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value}
                                    error={!!errors.country}
                                    helperText={errors.country ? errors.country.message : ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name });
                                    }}
                                />
                            )}
                        />




                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                // isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                //  isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                />
                            )}
                        />
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="purposeOfLoan"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFDescription />}
                                    label="Purpose of Loan  *"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.purposeOfLoan?.name}
                                    helperText={errors.purposeOfLoan?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="typeOfLoan"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFDescription />}
                                    label="Type of Loan  *"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.typeOfLoan?.name}
                                    helperText={errors.typeOfLoan?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>




                    <Grid item xs={12} md={6}>
                        <Controller
                            name="desiredLoanAmount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Desired Loan Amount "
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.desiredLoanAmount}
                                    helperText={errors.desiredLoanAmount ? errors.desiredLoanAmount.message : ''}
                                    placeholder={' Enter Loan Amount'}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="websiteLink"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="No of Floors/Units "
                                    fullWidth sicon={<OFWebsiteLink />}
                                    variant="outlined"
                                    error={!!errors.websiteLink}
                                    helperText={errors.websiteLink ? errors.websiteLink.message : ''}
                                    placeholder={'No of Floors/Units  *'}
                                />
                            )}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="businessCollaborationType"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Type of Loan"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.businessCollaborationType?.name}
                                    helperText={errors.businessCollaborationType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                // isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="preferredLoanTerm"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Preferred Loan Term"
                                    control={control}
                                    options={[
                                        { id: 1, name: 'Short Term' },
                                        { id: 2, name: 'Long Term' },


                                    ]}
                                    error={!!errors.preferredLoanTerm}
                                    helperText={errors.preferredLoanTerm ? errors.preferredLoanTerm.message : ''}
                                // isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyIncome"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Monthly Income"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.monthlyIncome}
                                    helperText={errors.monthlyIncome ? errors.monthlyIncome.message : ''}
                                    placeholder={'Enter amount'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="existingDebt"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Existing Debt (If any)"
                                    fullWidth sicon={<OFRupeeIcon />}
                                    variant="outlined"
                                    error={!!errors.existingDebt}
                                    helperText={errors.existingDebt ? errors.existingDebt.message : ''}
                                    placeholder={'Enter amount'}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="creditScore"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Credit Score (If available)"
                                    fullWidth sicon={<OFDescription />}
                                    variant="outlined"
                                    error={!!errors.creditScore}
                                    helperText={errors.creditScore ? errors.creditScore.message : ''}
                                    placeholder={'Enter credit score'}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>

                         <Controller
                            name="outletsLocationState"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MultipleSelectChip
                                    options={outletStates}
                                    label="Outlets are located at State(s)"
                                    value={value} // Pass array of objects with id and label
                                    onChange={onChange}
                                    required
                                    error={errors?.outletsLocationState?.message}
                                    helperText={errors.outletsLocationState ? errors.outletsLocationState.message : ''}
                                />
                            )}
                        /> 

                    </Grid> */}


                    {/* <Grid item xs={12} md={6}>
                        <Controller
                            name="websiteLink"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="How soon would you like to Move In? md={6}"
                                    fullWidth sicon={<OFWebsiteLink />}
                                    variant="outlined"
                                    error={!!errors.websiteLink}
                                    helperText={errors.websiteLink ? errors.websiteLink.message : ''}
                                    placeholder={'No of Days'}
                                />
                            )}
                        />
                    </Grid> */}

                    <Grid item xs={12} md={12}>
                        <Controller
                            name="additionalRequirements"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Additional Requirements  "
                                    fullWidth sicon={<OFComment />}
                                    variant="outlined"
                                    error={!!errors.additionalRequirements}
                                    helperText={errors.additionalRequirements ? errors.additionalRequirements.message : ''}
                                    placeholder={'Additional Requirements '}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <Controller
                            name="loanAgainstProperty"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Do you want to go for loan against Property"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.loanAgainstProperty?.message}
                                    helperText={errors.loanAgainstProperty ? errors.loanAgainstProperty.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        setLoanAgainstProperty(e.target.value);
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    {loanAgainstProperty === 'Yes' && (
                        <>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="typeOfProperty"
                                    control={control}
                                    render={({ field }) => (


                                        <CustomDropDown
                                            {...field}
                                            field={field}
                                            fullWidth sicon={<OFHouse1 />}
                                            label="Type of Property"
                                            required
                                            variant="outlined"
                                            option={ranges}
                                            defaultValue={field.value}
                                            displayEmpty
                                            error={!!errors.typeOfProperty?.name}
                                            helperText={errors.typeOfProperty?.name?.message || ''}
                                            changeCallBack={({ id, value }) => {


                                                field.onChange(id, value);
                                            }}
                                        // isViewMode={!isEditing}
                                        />

                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="propertyValue"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Property Value  *"
                                            fullWidth sicon={<OFRupeeIcon />}
                                            variant="outlined"
                                            error={!!errors.propertyValue}
                                            helperText={errors.propertyValue ? errors.propertyValue.message : ''}
                                            placeholder={'Additional Requirements '}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="propertyArea"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Property Area (in sq ft) *"
                                            fullWidth sicon={<OFHouse1 />}
                                            variant="outlined"
                                            error={!!errors.propertyArea}
                                            helperText={errors.propertyArea ? errors.propertyArea.message : ''}
                                            placeholder={'Additional Requirements '}
                                        />
                                    )}
                                />
                            </Grid>

                        </>)}
                </Grid>
            </Box>


        </>
    );
};

export default LoanPropertyForm;
