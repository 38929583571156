

import React from 'react';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import { OFAddress, OFCountry, OFDescription, OFEditIcon, OFPercentageIcon, OFRupeeIcon, OFStore, OFTerritoryIcon, OFYesNoIcon } from '../../../icons/CustomIcon';
import { CustomInputWithDropdown } from '../../../helpers/CustomInputWithDropdown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomInput from '../../../helpers/custom-input';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomInputFee from '../../../helpers/CustomInputFee';
import { oneFranHubtheme } from '../../../theme/theme';

const PropertyDetailsForm = ({ control, errors, setValue,


    expansionStates,
    isDashboardMode,
    setIsEditing,
    isEditing,
    states,
    setSelectedState,
    expansionPlan,
    ranges

}) => {


    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5">Franchisor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperFranchisor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }
            <Box
                paddingX={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Franchise Offering Details</Typography>
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}
                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        {/* <Controller
                            name="franchiserModalType"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    required
                                    mainLabel="Franchise Model Type"
                                    control={control}
                                    options={[
                                        { id: 'Single-Unit Franchise', name: 'Single-Unit Franchise' },
                                        { id: 'Multi-Unit Franchise', name: 'Multi-Unit Franchise' },
                                        { id: 'Master Franchise', name: 'Master Franchise' },
                                        { id: 'Area Development Franchise', name: 'Area Development Franchise' }
                                    ]}
                                    error={!!errors.franchiserModalType}
                                    helperText={errors.franchiserModalType ? errors.franchiserModalType.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        /> */}
                        <Controller
                            name="franchiserModalType"
                            control={control}
                            render={({ field }) => (
                                <CustomCheckbox
                                    {...field} // Spread the field props
                                    mainLabel="Franchise Model Type"
                                    control={control}
                                    sicon={OFAddress}
                                    options={[
                                        { id: 1, name: 'Single-Unit Franchise' },
                                        { id: 2, name: 'Multi-Unit Franchise' },
                                        { id: 3, name: 'Master Franchise' },
                                        { id: 4, name: 'Area Development Franchise' }
                                    ]}
                                    error={!!errors.franchiserModalType}
                                    helperText={errors.franchiserModalType ? errors.franchiserModalType.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="royaltyFee"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <CustomInputFee
                                    label={"Royalty Fee"}
                                    options={[
                                        { value: 'percentage', label: 'Percentage', icon: <OFPercentageIcon /> },
                                        { value: 'fixedAmount', label: 'Fixed Amount', icon: <OFRupeeIcon /> },
                                    ]}
                                    selectedType={value?.type || ''}
                                    onTypeChange={(type) => onChange({ ...value, type })}
                                    value={value?.value || ''}
                                    onValueChange={(val) => onChange({ ...value, value: val })}
                                    placeholder="Enter amount"

                                    error={!!error?.type || !!error?.value}
                                    helperText={error?.type?.message || error?.value?.message || ''}
                                    required
                                    isViewMode={!isEditing}
                                    sicon={OFPercentageIcon}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="initialFranchiseFee"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Initial Franchise Fee"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.initialFranchiseFee}
                                    helperText={errors.initialFranchiseFee ? errors.initialFranchiseFee.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                                // <CustomInput
                                //     {...field} required
                                //     label="Initial Franchise Fee"
                                //     fullWidth sicon={<OFRupeeIcon />}
                                //     variant="outlined"
                                //     error={!!errors.initialFranchiseFee}
                                //     helperText={errors.initialFranchiseFee ? errors.initialFranchiseFee.message : ''}
                                //     placeholder={'Initial Franchise Fee'}
                                //     isViewMode={!isEditing}

                                // />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* <Controller
                            name="royaltyFee"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <CustomInputWithDropdown
                                    label="Royalty Fee"
                                    options={{
                                        "type": "percentage",
                                        "value": 2000
                                    }}
                                    onChange={onChange}
                                    onIconChange={(iconName) => {
                                        setValue('royaltyFee', {
                                            [iconName === 'percent' ? 'percentage' : 'fixedAmount']: value.percentage || value.fixedAmount,
                                        });
                                        setValue('iconType', iconName);
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    error={!!error}
                                    helperText={error ? error.message : ''}
                                    value={value.percentage || value.fixedAmount || ''}
                                />
                            )}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <Controller
                            name="territoryExclusive"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Territory Exclusivity (Is exclusivity offered?)"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.territoryExclusive?.message}
                                    helperText={errors.territoryExclusive ? errors.territoryExclusive.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="territoryDetails"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Define the territory"
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    variant="outlined"
                                    error={!!errors.territoryDetails}
                                    helperText={errors.territoryDetails ? errors.territoryDetails.message : ''}
                                    placeholder={'Define the territory'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <Controller
                            name="franchiseKitAvailable"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Franchise Kit Availability (Brochures, Company Profile, Marketing Materials)"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.franchiseKitAvailable?.message}
                                    helperText={errors.franchiseKitAvailable ? errors.franchiseKitAvailable.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="expectedROImonths"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Expected Return on Investment (ROI) (In Months)"
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    variant="outlined"
                                    error={!!errors.expectedROImonths}
                                    helperText={errors.expectedROImonths ? errors.expectedROImonths.message : ''}
                                    placeholder={'10 months'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="payBackPeriodMonths"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Payback Period for Franchise (In Months)"
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    variant="outlined"
                                    error={!!errors.payBackPeriodMonths}
                                    helperText={errors.payBackPeriodMonths ? errors.payBackPeriodMonths.message : ''}
                                    placeholder={'10 months'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="additionalInvestmentRequirements"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    label="Additional Investment Requirements"
                                    variant="outlined"
                                    option={[{
                                        "id": 2,
                                        "name": "Marketing 5000",
                                        "type": "NUMBER"
                                    }]}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.additionalInvestmentRequirements}
                                    helperText={errors.additionalInvestmentRequirements ? errors.additionalInvestmentRequirements.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>

                </Grid>
            </Box>
            <Divider />
            <Box
                paddingX={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={1}>
                    <Typography variant="TITLE1">Expansion Plans</Typography>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="expansionPlan"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field}
                                    //required
                                    // label="expansionPlan"
                                    data={[
                                        { value: "India", label: "India" },
                                        { value: "International", label: "International" },
                                    ]}
                                    error={errors?.expansionPlan?.message}
                                    helperText={errors.expansionPlan ? errors.expansionPlan.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e); // Update react-hook-form state
                                        //isTerritoryExclusive(e.target.value);
                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFYesNoIcon}
                                />
                            )}
                        />
                    </Grid>

                    {expansionPlan === 'India' && (<>

                        <Grid item xs={12} md={6}>

                            <Controller
                                name="expansionState"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <MultipleSelectChip
                                        options={states}
                                        label="State(s)"
                                        value={value} // Pass array of objects with id and label
                                        onChange={onChange}
                                        required
                                        error={errors?.expansionState?.message}
                                        helperText={errors.expansionState ? errors.expansionState.message : ''}
                                        isViewMode={!isEditing}
                                    />
                                )}
                            />


                        </Grid>
                    </>)}
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="preferredMarket"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFAddress />}
                                    label="Preferred Market Conditions"
                                    variant="outlined"
                                    option={[{
                                        "id": 2,
                                        "name": "Marketing 5000",
                                        "type": "NUMBER"
                                    }]}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.preferredMarket}
                                    helperText={errors.preferredMarket ? errors.preferredMarket.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Controller
                            name="tgtNoOfNewOutlet"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Targeted Number of New Outlets"
                                    fullWidth sicon={<OFTerritoryIcon />}
                                    variant="outlined"
                                    error={!!errors.tgtNoOfNewOutlet}
                                    helperText={errors.tgtNoOfNewOutlet ? errors.tgtNoOfNewOutlet.message : ''}
                                    placeholder={'10 months'}
                                    isViewMode={!isEditing}

                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>

        </>);
};


export default PropertyDetailsForm;
