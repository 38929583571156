

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFBuilding5, OFCalendar, OFCalendar2, OFCastle, OFCheckList, OFCity1, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse1, OFHouse4, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFNoOfEmployees, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';





const SBusinessDetailsForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Business on Sales</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperSellMyBusiness />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Business Information</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>





                    <Grid item xs={12} md={4}>
                        <Controller
                            name="companyName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Company Name"
                                    required
                                    fullWidth sicon={<OFHouse1 />}
                                    variant="outlined"
                                    error={!!errors.companyName}
                                    helperText={errors.companyName ? errors.companyName.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="establishmentYear"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <CustomDropdownSingle
                                    {...field}
                                    fullWidth sicon={<OFCalendar />}
                                    required
                                    label="Establishment Year"
                                    option={["1900", "1915", "2020"]}
                                    defaultValue={field.value}
                                    changeCallBack={(value) => {
                                        field.onChange(value);
                                    }}
                                    error={!!errors.establishmentYear}
                                    helperText={errors.establishmentYear?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                        {/* <Controller
                            name="establishmentYear"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Establishment Year"
                                    required
                                    fullWidth sicon={<OFMailAtSign />}
                                    variant="outlined"
                                    error={!!errors.establishmentYear}
                                    helperText={errors.establishmentYear ? errors.establishmentYear.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        /> */}
                    </Grid>




                    <Grid item xs={12} md={4}>
                        <Controller
                            name="employeeCount"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Employee Count"
                                    required
                                    fullWidth sicon={<OFNoOfEmployees />}
                                    variant="outlined"
                                    error={!!errors.employeeCount}
                                    helperText={errors.employeeCount ? errors.employeeCount.message : ''}
                                    placeholder={'Employee Count'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                        {/* <Controller
                            name="employeeCount"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Employee Count"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.employeeCount}
                                    helperText={errors.employeeCount ? errors.employeeCount.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        /> */}

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="companyType"
                            control={control}
                            render={({ field }) => (


                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFCity1 />}
                                    label="Company Type"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.companyType?.name}
                                    helperText={errors.companyType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="businessType"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Business Type"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.businessType?.name}
                                    helperText={errors.businessType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry"
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industry?.name}
                                    helperText={errors.industry?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {/* <Controller
                            name="sector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field} required
                                    // field={field}
                                    fullWidth sicon={<OFSector />}
                                    label={'Sector'}
                                    variant="outlined"
                                    option={industrySectors}
                                    defaultValue={field.value}
                                    error={!!errors.city}
                                    helperText={errors.sector ? errors.sector.message : ''}
                                    changeCallBack={({ id, name }) => {
                                        //  console.log('Sector selected:', value);
                                        setSelectedSector(id);
                                        field.onChange(id, name);
                                    }}
                                />

                            )}
                        /> */}
                        <Controller
                            name="industrySector"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth sicon={<OFSector />}
                                    label="Sector"
                                    option={industrySectors}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedSector(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.industrySector?.name}
                                    helperText={errors.industrySector?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={4}>
                        <Controller
                            name="businessWebsite"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Business Website"
                                    required
                                    fullWidth sicon={<OFWebsiteLink />}
                                    variant="outlined"
                                    error={!!errors.businessWebsite}
                                    helperText={errors.businessWebsite ? errors.businessWebsite.message : ''}
                                    placeholder={'Address'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="facilities"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Facilities  (e.g., production units, office)"
                                    required
                                    fullWidth sicon={<OFCheckList />}
                                    variant="outlined"
                                    error={!!errors.facilities}
                                    helperText={errors.facilities ? errors.facilities.message : ''}
                                    placeholder={'Address'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>



                    <Grid item xs={12} md={4}>
                        <Controller
                            name="customerBase"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Customer Base"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.customerBase?.name}
                                    helperText={errors.customerBase ? errors.customerBase?.name?.message : ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />


                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="body" >Describe About the Business / Company Summary </Typography>

                        <Controller
                            name="businessSummary"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field} required

                                    sx={{ marginTop: 0.5 }}
                                    fullWidth sicon={<OFMailIcon />}
                                    size="large"
                                    placeholder="Describe About the Business / Company Summary"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 50 }}
                                    error={!!errors.businessSummary}
                                    helperText={errors.businessSummary ? errors.businessSummary.message : ''}
                                    FormHelperTextProps={{
                                        sx: { fontSize: '14px', color: 'black', marginLeft: 0, marginTop: 0.5 }
                                    }}

                                />
                            )}
                        />

                    </Grid>
                </Grid>
            </Box>



        </>);
};

export default SBusinessDetailsForm;
