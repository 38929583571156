

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';
import InvestorDetails from './InvestorDetails';
import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';




const InvestorDetailsForm = ({ control, errors, setValue, watch, getValues, isBussnessProfile,

    countries,
    cities,
    states,
    industries,
    ranges,
    industrySectors,
    setSelectedCity,
    setSelectedCountry,
    setSelectedIndustry,
    setSelectedSector, setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,
    loanSupportRequired


}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));





    const FRANCHISE_COMMENCED_YEAR_LIST = [...Array(new Date().getFullYear() - 1900 + 1).keys()].map(year => year + 1900);







    const countryCodeOptions = [
        { label: '+91', value: '91' },
        { label: '+1', value: '1' },
        { label: '+44', value: '+44' },


    ];





    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="TITLE3">Investor Registration</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressStepperInvestor />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Investor Details</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>




                    <Grid item xs={12} md={6}>


                        <Controller
                            name="alternateMobileNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Alternate Mobile No."
                                    fullWidth

                                    sicon={< OFPhone />}
                                    countryCodeOptions={[{ value: '91', label: 'IND(+91)' }, { value: '1', label: 'US(+1)' }]}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={
                                        (errors.alternateMobileNo?.countryCode && errors.alternateMobileNo.countryCode.message) ||
                                        (errors.alternateMobileNo?.phoneNumber && errors.alternateMobileNo.phoneNumber.message) ||
                                        ''
                                    }
                                    error={!!errors.alternateMobileNo}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />





                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="alternateEmailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Alternate Email Id"
                                    fullWidth sicon={<OFMailAtSign />}
                                    variant="outlined"
                                    error={!!errors.alternateEmailId}
                                    helperText={errors.alternateEmailId ? errors.alternateEmailId.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="street"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Address"
                                    required
                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.street}
                                    helperText={errors.street ? errors.street.message : ''}
                                    placeholder={'Address'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />} // Replace with your actual icon
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value} // Ensure defaultValue is an object { id, name }

                                    error={!!errors.country?.name}
                                    helperText={errors.country?.name?.message || ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name }); // Pass { id, name } to form state
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}> </Grid>








                    <Grid item xs={12} md={6}>


                        {/* <Controller
                            name="interestedIndustry"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}

                                    fullWidth sicon={<OFIndustry />}
                                    label="Industry Type (Interested In)"
                                    required
                                    option={industries}
                                    defaultValue={field.value}

                                    changeCallBack={({ id, name }) => {

                                        setSelectedIndustry(id)
                                        field.onChange({ id, name });
                                    }}
                                    error={!!errors.interestedIndustry?.name}
                                    helperText={errors.interestedIndustry?.name?.message || ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        /> */}
                        <Controller
                            name="interestedIndustry"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MultipleSelectChip
                                    options={industries}

                                    label="Industry Type (Interested In)"
                                    value={value} // Pass array of objects with id and label
                                    onChange={onChange}
                                    required
                                    error={errors?.interestedIndustry?.message}
                                    helperText={errors.interestedStates ? errors.interestedIndustry.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="investmentRange"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Investment Range"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.investmentRange?.name}
                                    helperText={errors.investmentRange?.name?.message || ''}

                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />


                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="availableCapitalRange"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFRupeeIcon />}
                                    label="Available  Capital"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.availableCapitalRange?.name}
                                    helperText={errors.availableCapitalRange?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="hsoonToInvestRange"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFCalendar2 />}
                                    label="How soon would you like to invest?"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.hsoonToInvestRange}
                                    helperText={errors.hsoonToInvestRange?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="educationalQualification"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFBackpack1 />}
                                    label="Education Qualification"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.educationalQualification}
                                    helperText={errors.educationalQualification?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="occupation"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field}
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Occupation"
                                    required
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.occupation}
                                    helperText={errors.occupation?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="lookingForBusinessType"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Looking for Business Type"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.lookingForBusinessType}
                                    helperText={errors.lookingForBusinessType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>

                        <Controller
                            name="interestedStates"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MultipleSelectChip
                                    options={states}
                                    //sicon={<OFCountry />}
                                    label={isEditing ? "In which states are you interested in finding a business?" : "Looking for Business In (State)"}
                                    value={value} // Pass array of objects with id and label
                                    onChange={onChange}
                                    required
                                    error={errors?.interestedStates?.message}
                                    helperText={errors.interestedStates ? errors.interestedStates.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />


                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Controller
                            name="interestedCities"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <MultipleSelectChip
                                    options={states}
                                    // sicon={<OFCountry />}
                                    label={isEditing ? "In which cities are you interested in finding a business?" : "Looking for Business In (City)"}
                                    value={value} // Pass array of objects with id and label
                                    onChange={onChange}
                                    required
                                    error={errors?.interestedCities?.message}
                                    helperText={errors.interestedCities ? errors.interestedCities.message : ''}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />


                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="investorType"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="Investor Type"
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.investorType}
                                    helperText={errors.investorType?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="investmentPreference"
                            control={control}
                            render={({ field }) => (

                                <CustomDropDown
                                    {...field} required
                                    field={field}
                                    fullWidth sicon={<OFBriefcase />}
                                    label="What is your Investment preferences "
                                    variant="outlined"
                                    option={ranges}
                                    defaultValue={field.value}
                                    displayEmpty
                                    error={!!errors.investmentPreference}
                                    helperText={errors.investmentPreference?.name?.message || ''}
                                    changeCallBack={({ id, value }) => {


                                        field.onChange(id, value);
                                    }}
                                    isViewMode={!isEditing}
                                />

                            )}
                        />

                    </Grid>




                    <Grid item xs={12} md={6}>
                        <Controller
                            name="about"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field} required
                                    label="Brief about yourself"
                                    fullWidth sicon={<OFAppleReminder />}
                                    variant="outlined"
                                    error={!!errors.about}
                                    helperText={errors.about ? errors.about.message : ''}
                                    placeholder={'Enter details'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>







                    <Grid item xs={12} md={3}>

                        <Controller
                            name="legalSupportRequired"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Legal support"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.legalSupportRequired?.message}
                                    helperText={errors.legalSupportRequired ? errors.legalSupportRequired.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);

                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFLegalDocument1}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={3}>

                        <Controller
                            name="loanSupportRequired"
                            control={control}
                            render={({ field }) => (
                                <RadioButtonGroup
                                    {...field} required
                                    label="Loan Supprt?"
                                    data={[
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]}
                                    error={errors?.loanSupportRequired?.message}
                                    helperText={errors.loanSupportRequired ? errors.loanSupportRequired.message : ''}
                                    onChange={(e) => {
                                        field.onChange(e);

                                    }}
                                    isViewMode={!isEditing}
                                    sicon={OFRupeeIcon}
                                />
                            )}
                        />

                    </Grid>

                    {loanSupportRequired === 'Yes' &&
                        <>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="desiredLoanAmount"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Desired Loan Amount  *"
                                            fullWidth sicon={<OFRupeeIcon />}
                                            variant="outlined"
                                            error={errors?.desiredLoanAmount?.message}
                                            helperText={errors.desiredLoanAmount ? errors.desiredLoanAmount.message : ''}
                                            placeholder={'Desired Loan Amount  *'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>

                                <Controller
                                    name="preferredLoanTerm"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioButtonGroup
                                            {...field} required
                                            label="Preferred Loan Term  *"
                                            data={[
                                                { value: "Short Term", label: "Short Term" },
                                                { value: "Long Term", label: "Long Term" },
                                            ]}
                                            error={errors?.preferredLoanTerm?.message}
                                            helperText={errors.preferredLoanTerm ? errors.preferredLoanTerm.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);

                                            }}
                                            isViewMode={!isEditing}
                                            sicon={OFCalendar2}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="monthlyIncome"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Monthly Income  *"
                                            fullWidth sicon={<OFRupeeIcon />}
                                            variant="outlined"
                                            error={!!errors.monthlyIncome}
                                            helperText={errors.monthlyIncome ? errors.monthlyIncome.message : ''}
                                            placeholder={'Alternate Email Id'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="existingDebt"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Existing Debts (If any)"
                                            fullWidth sicon={<OFRupeeIcon />}
                                            variant="outlined"
                                            error={!!errors.existingDebt}
                                            helperText={errors.existingDebt ? errors.existingDebt.message : ''}
                                            placeholder={'Alternate Email Id'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="creditScore"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Credit Score (If available) "
                                            fullWidth sicon={<OFCalendar2 />}
                                            variant="outlined"
                                            error={!!errors.creditScore}
                                            helperText={errors.creditScore ? errors.creditScore.message : ''}
                                            placeholder={'Alternate Email Id'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Controller
                                    name="specificRequirementOnLoan"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Any Special Requirements or Preferences  on loan "
                                            fullWidth sicon={<OFAppleReminder />}
                                            variant="outlined"
                                            error={!!errors.specificRequirementOnLoan}
                                            helperText={errors.specificRequirementOnLoan ? errors.specificRequirementOnLoan.message : ''}
                                            placeholder={'Alternate Email Id'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Controller
                                    name="loanAgainstProperty"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioButtonGroup
                                            {...field} required
                                            label="Do you want to go for Loan against Property?  *"
                                            data={[
                                                { value: "Yes", label: "Yes" },
                                                { value: "No", label: "No" },
                                            ]}
                                            error={errors?.loanAgainstProperty?.message}
                                            helperText={errors.loanAgainstProperty ? errors.loanAgainstProperty.message : ''}
                                            onChange={(e) => {
                                                field.onChange(e);

                                            }}
                                            isViewMode={!isEditing}
                                            sicon={OFHouse4}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="typeOfProperty"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomDropDown
                                            {...field}
                                            field={field}
                                            fullWidth sicon={<OFRupeeIcon />}
                                            label="Occupation"
                                            required
                                            variant="outlined"
                                            option={ranges}
                                            defaultValue={field.value}
                                            displayEmpty
                                            error={!!errors.typeOfProperty}
                                            helperText={errors.typeOfProperty?.name?.message || ''}
                                            changeCallBack={({ id, value }) => {


                                                field.onChange(id, value);
                                            }}
                                            isViewMode={!isEditing}
                                        />


                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="propertyValue"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Property Value  *"
                                            fullWidth sicon={<OFRupeeIcon />}
                                            variant="outlined"
                                            error={!!errors.propertyValue}
                                            helperText={errors.propertyValue ? errors.propertyValue.message : ''}
                                            placeholder={'Type of Property  *'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="propertyArea"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Property Area (In sq ft)  *"
                                            fullWidth sicon={<OFMailAtSign />}
                                            variant="outlined"
                                            error={!!errors.propertyArea}
                                            helperText={errors.propertyArea ? errors.propertyArea.message : ''}
                                            placeholder={'Property Value  *'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={isEditing ? 6 : 12}>
                                <Controller
                                    name="doYouWantSaySomething"
                                    control={control}
                                    render={({ field }) => (
                                        <CustomInput
                                            {...field}
                                            label="Do you want to say something?"
                                            fullWidth sicon={<OFAppleReminder />}
                                            variant="outlined"
                                            error={!!errors.doYouWantSaySomething}
                                            helperText={errors.doYouWantSaySomething ? errors.doYouWantSaySomething.message : ''}
                                            placeholder={'Property Area (In sq ft)  *'}
                                            isViewMode={!isEditing}
                                        />
                                    )}
                                />
                            </Grid>

                        </>

                    }

                    {isEditing &&
                        <Grid item xs={12} md={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Controller
                                name="acceptTermAndConditions"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!!field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="I agree to the Terms & Conditions"
                                        error={errors?.acceptTermAndConditions?.message}
                                        helperText={errors.acceptTermAndConditions ? errors.acceptTermAndConditions.message : ''}
                                    />
                                )}
                            />
                            {errors.acceptTermAndConditions && (
                                <p style={{ color: 'red' }}>
                                    {errors.acceptTermAndConditions.message}
                                </p>
                            )}
                        </Grid>
                    }
                </Grid>
            </Box>



        </>);
};

export default InvestorDetailsForm;
