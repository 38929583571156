
import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Paper, Button, useMediaQuery } from '@mui/material';
import LocationIcon from "../../assets/icons/LocationIcon.svg"
import { oneFranHubtheme } from "../../theme/theme"
import FranchiseDetailedPage from '../../pages/detiled-page/FranchiseDetailedPage';
import { useNavigate } from 'react-router-dom';
import { fetchSingleImage } from '../../axios/api-service';
import { API } from '../../axios/api-path';
import industryColors from '../../utils/industryColors';
import { formatInvestment } from '../../utils/UtilFunctions'


const TopFrCard = ({ entityCode, imageId, category, location, description, investmentRange, profitMargin, outlets, noOfCards }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));

    const navigate = useNavigate();

    const showCardDetails = () => {

        navigate('/franchise/business-page', {
            state: {
                entityCode,

            }
        });

    };
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!imageId) {
            console.error('No imageId provided');
            setLoading(false);
            return;
        }

        const fetchImage = async () => {
            try {
                //  setLoading(true);
                const path = API.getSingleImage();

                const image = await fetchSingleImage(path, imageId);
                if (image) {
                    setImage(image);
                } else {
                    console.error('Image not found');
                }

            } catch (err) {
                console.error('Error fetching image:', err);
            } finally {
                setLoading(false);
                console.log('Image fetching process completed.');
            }
        };

        fetchImage();
    }, [imageId]);


    const industryColor = industryColors[category] || industryColors.default;

    return (
        <Grid item xs={12} sm={12} md={3.8} mt={2} mb={4} >
            <Paper
                square={false}
                elevation={3}
                sx={{
                    borderRadius: '20px',
                    backgroundColor: '#fff',
                    width: '100%', // Ensure the Paper takes the full width of the Grid item
                    '&:hover': {
                        backgroundColor: '#E7F0FF', // Changes background to red on hover
                    },



                }}
            >
                <Box padding={2}>
                    {image ? (
                        <Box>  <img
                            src={image}
                            alt="Card Image"
                            style={{
                                width: '100%',
                                height: 'auto',
                                // maxWidth: '379px',
                                //  maxHeight: '163px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                        /></Box>

                    ) : (
                        <>
                            <Box>
                                <img
                                    src={'/images/NoCard.png'}
                                    alt="Card Image"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        // maxWidth: '379px',
                                        //  maxHeight: '163px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                    }}
                                />

                            </Box>
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '0',
                        paddingBottom: '0',
                        borderRadius: '4px',
                    }}
                >
                    <Typography
                        variant="Small"
                        style={{
                            position: "relative",
                            borderRadius: "23.59px",
                            // backgroundColor: "rgba(105, 0, 103, 0.2)",
                            // color: "#9a3198",
                            backgroundColor: industryColor,
                            color: 'white',
                            width: "auto", // Set width to auto to fit content
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0.375rem 0.75rem", // Padding for spacing
                            boxSizing: "border-box",
                            textAlign: "center",
                            fontSize: "0.875rem",

                            whiteSpace: "nowrap", // Prevents text wrapping
                        }}
                    >
                        {category}
                    </Typography>


                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >

                        <img src={LocationIcon}></img>
                        <Typography variant='Body' sx={{ lineHeight: '20.16px' }} >
                            {location}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    padding={2}
                    sx={{
                        textAlign: 'left',
                        height: 'auto',
                        marginBottom: '2rem',
                        overflow: 'hidden',
                        width: '100%'
                    }}
                >

                    <Typography
                        variant='TITLE3'
                        style={{
                            width: "100%",

                            color: '#000000',
                            textAlign: "left",
                            display: "inline-block",
                            boxSizing: "border-box"
                        }}
                    >
                        {description}

                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '0',
                        marginBottom: '0.5rem',
                        borderRadius: '4px',
                    }}
                >

                    <Typography variant='Body' style={{ width: "60%", position: "relative", color: '#555', textAlign: "left", display: "inline-block", opacity: "0.5", }}>
                        Investment range
                    </Typography>
                    <Typography variant='BodyBold' style={{ width: "40%", position: "relative", fontWeight: "600", color: '#000', textAlign: "right", display: "inline-block", }}>
                        {`${formatInvestment(investmentRange?.startIndex)} - ${formatInvestment(investmentRange?.endIndex)}`}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '0',
                        marginBottom: '0.5rem',
                        borderRadius: '4px',
                    }}
                >

                    <Typography variant='Body' style={{ width: "70%", position: "relative", color: '#555', textAlign: "left", display: "inline-block", opacity: "0.5", }}>
                        Profit margin per year
                    </Typography>
                    <Typography variant='BodyBold' style={{ width: "30%", position: "relative", fontWeight: "600", color: '#000', textAlign: "right", display: "inline-block", }}>
                        {profitMargin}
                    </Typography>

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '0',
                        marginBottom: '0.5rem',
                        borderRadius: '4px',
                    }}
                >

                    <Typography variant='Body' style={{ width: "70%", position: "relative", color: '#555', textAlign: "left", display: "inline-block", opacity: "0.5", }}>
                        Franchise Outlets
                    </Typography>
                    <Typography variant='BodyBold' style={{ width: "30%", position: "relative", fontWeight: "600", color: '#000', textAlign: "right", display: "inline-block", }}>
                        {outlets}
                    </Typography>

                </Box>

                <Box
                    paddingX={1}
                    paddingTop={1}
                    paddingBottom={3}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                >
                    <Button
                        variant="outlined"
                        onClick={() => showCardDetails()}
                        sx={{
                            alignItems: 'center',
                            borderRadius: '50px',
                            borderColor: '#0D2659',
                            color: '#0D2659',
                            padding: '8px 16px',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20.16px',
                            width: '20%',
                            height: '3rem',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#0d51d9',
                                backgroundColor: '#f5f5f5',
                            },
                        }}
                    >
                        View more
                    </Button>

                </Box>

            </Paper>
        </Grid>
    );
};

export default TopFrCard;