
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Box, IconButton, Typography, useMediaQuery, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { oneFranHubtheme } from '../../theme/theme';
import CustomView from '../../helpers/CustomView';
import { OFBackIcon, OFNextIcon } from '../../icons/CustomIcon';
import { useLocation } from 'react-router-dom';
import { fetchPropertyInfo, fetchSingleCardDetails, fetchSingleImage } from '../../axios/api-service';
import { API } from '../../axios/api-path';
import { GenericCard } from '../../utils/GenericCard'
import BusinessInterestForm from './BusinessInterestForm';

const DealersDetailedPage = () => {


    const location = useLocation();
    const {
        entityCode,
        image,
        category,
        location: franchiseLocation,
        description,
        investmentRange,
        profitMargin,
        outlets
    } = location.state || {};

    console.log(" entity code inside detailed page " + entityCode)

    const [cardDetails, setCardDetails] = useState([]);
    const [appearanceMedia, setAppearanceMedia] = useState([]);
    const [images, setImages] = useState([]);


    const [currentImage, setCurrentImage] = useState(0);

    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchSingleCardDetails({ franchiserCode: entityCode });

                if (response.status === "success" && response.data) {
                    setCardDetails(response.data);

                    setAppearanceMedia(response.data.appearanceMedia)
                } else {
                    // Handle unexpected response
                    console.error("Unexpected response structure", response);
                }
            } catch (err) {
                // Handle error
                console.error("Error fetching data", err);
            }
        };

        if (entityCode) fetchData();
    }, []);












    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchSingleCardDetails({ franchiserCode: entityCode });

                if (response.status === "success" && response.data) {
                    setCardDetails(response.data);
                    setAppearanceMedia(response.data.appearanceMedia);
                } else {
                    console.error("Unexpected response structure", response);
                }
            } catch (err) {
                console.error("Error fetching data", err);
            }
        };

        if (entityCode) fetchData();
    }, [entityCode]);




    useEffect(() => {
        if (!appearanceMedia) {
            console.error('appearanceMedia is undefined');
            return;
        }

        const fetchImages = async () => {
            const path = API.getSingleImage();

            const imageKeys = ['image1', 'image2', 'image3', 'image4', 'brandLogo'];
            try {

                const fetchedImages = await Promise.all(
                    imageKeys.map(async (key) => {
                        if (appearanceMedia[key] && appearanceMedia[key].id) {
                            try {

                                const image = await fetchSingleImage(path, appearanceMedia[key].id);
                                return image;
                            } catch (err) {
                                console.error(`Error fetching ${key}:`, err);
                                return null;
                            }
                        }
                        return null;
                    })
                );


                setImages(fetchedImages.filter(Boolean));
            } catch (err) {
                console.error('Error fetching images:', err);
            } finally {
                // console.log('Image fetching process completed.');
            }
        };

        fetchImages();
    }, [appearanceMedia]);








    const handleNext = () => {
        setCurrentImage((prev) => (prev + 1) % images.length);
    };

    const handleBack = () => {
        setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
    };


    const radius1 = '18px'
    const franchiseDetails1 = [
        { label: 'Company Name', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Year of Commencement', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'No of franchise outlets', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Outlets are located at', value: `${cardDetails?.expansion?.initialFranchiseFee?.startIndex} - ${cardDetails?.expansion?.initialFranchiseFee?.endIndex}` }
    ];
    const franchiseDetails2 = [
        { label: 'Franching model type', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Franchise Fee', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'Royalty Fee', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Franchise Kit availability', value: `${cardDetails?.expansion?.initialFranchiseFee?.startIndex} - ${cardDetails?.expansion?.initialFranchiseFee?.endIndex}` },
        { label: 'Expected revenue projections', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Break Even', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'Operating Manuals', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Duration of training', value: `${cardDetails?.expansion?.initialFranchiseFee?.startIndex} - ${cardDetails?.expansion?.initialFranchiseFee?.endIndex}` },
        { label: 'Location of training', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Content of training', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'On going support services of training', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Ope rational support', value: `${cardDetails?.expansion?.initialFranchiseFee?.startIndex} - ${cardDetails?.expansion?.initialFranchiseFee?.endIndex}` },
        { label: 'Marketing support', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Field support for franchises', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'Ope rational support', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },


    ];

    const franchiseDetails3 = [
        { label: 'Floor Area(Space requirement)', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Preferred property', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'Assistance for site selection from brand', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Standard Franchise agreement', value: `${cardDetails?.expansion?.initialFranchiseFee?.startIndex} - ${cardDetails?.expansion?.initialFranchiseFee?.endIndex}` },
        { label: 'Duration of franchise contract', value: cardDetails?.businessProfessional?.franchiseCommenceYear },
        { label: 'Contract renewal', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },
        { label: 'Insurance requirements', value: cardDetails?.expansion?.royaltyFee?.percentage || cardDetails?.expansion?.royaltyFee?.fixedAmount },
        { label: 'Licensing requirements', value: cardDetails?.financialProjectionTraining?.initialInvestmentRange?.name },

    ];

    return (








        <Box sx={{ backgroundColor: '#F1F5F9', boxSizing: 'border-box', paddingX: 1 }}>
            <Container maxWidth={'xl'}>
                <Grid container spacing={2} style={{ padding: 4 }}>

                    <Grid item xs={12} md={9}>
                        <Paper elevation={3} style={{
                            height: '100%',

                            display: 'flex',
                            //   justifyContent: 'center',
                            //   alignItems: 'center',
                            // height: '40vh',
                            position: 'relative',
                            borderTopLeftRadius: '24px', // Rounds the top-left corner
                            borderBottomLeftRadius: '24px', // Rounds the bottom-left corner
                            // overflow: 'hidden', // Ensures content follows the rounded corners

                        }}>

                            {/* Image Display */}
                            {/* <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40vh',
                                    position: 'relative',
                                }}
                            >
                               
                                {images.length > 0 && (
                                    <img
                                        src={images[currentImage]}
                                        alt="current"
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                )}

                            </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40vh',
                                    position: 'relative',

                                }}
                            >
                                {images.length > 0 ? (
                                    <img
                                        src={images[currentImage]}
                                        alt="current"
                                        style={{
                                            width: '100%', height: '100%',
                                            borderRadius: '16px',
                                            objectFit: 'cover'
                                        }}

                                    />
                                ) : (
                                    <p></p>
                                )}
                            </Box>

                            {/* Center Bottom Icons */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 20,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <IconButton onClick={handleBack} color="primary">
                                    <OFBackIcon fontSize="large" />
                                </IconButton>
                                <IconButton onClick={handleNext} color="primary">
                                    <OFNextIcon fontSize="large" />
                                </IconButton>
                            </Box>

                        </Paper>
                    </Grid>

                    {/* Second Part */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} style={{
                            height: '100%'
                            ,
                            borderRadius: { radius1 }
                        }}>
                            <img
                                src="advertisement1.jpg"

                                alt="advertisement"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Paper>
                    </Grid>

                    {/*Business Details */}
                    <Grid item xs={12} md={9} >
                        <Box >

                            <GenericCard data={franchiseDetails1} heading="Franchisor" height="30vh" width="100%" />

                        </Box>


                        <Box mt={3} mb={3}>
                            <Paper elevation={3} style={{
                                height: 'auto',
                                //overflowY: 'auto',
                                borderRadius: { radius1 }
                            }}>


                                <Box sx={{ padding: 4 }}>
                                    <Box mb={2}>
                                        <Typography variant="TITLE2" sx={{ mb: 2 }}>

                                            Describe your business
                                        </Typography>

                                    </Box>

                                    <Typography>
                                        `Alliance Recruitment Agency is one of the leading global recruitment, staffing, and executive search companies. We operate through a network of worldwide teams, with specialists in recruitment for different industries. Our recruitment agency is everywhere you need us, with teams on four continents.
                                    </Typography> <Typography>
                                        Alliance Recruitment Agency strives to help businesses put together a staff of highly efficient and skilled professionals, and also serves employees by opening up new vistas of job opportunities for them. We take pride in lending our hand in creating the kind of professional environment that would fulfil the mission and vision that enterprises are built upon. Furthermore, we operate in highly dynamic and diversified global markets. Alliance Recruitment Agency is a Low Cost Recruitment Franchise Provider. Apply now for Alliance International Services franchise.
                                    </Typography> <Typography>
                                        We are a global recruitment company offering manpower consultancy, hire-train-deploy staffing, RPO, offshore hiring, headhunting, and executive recruitment services in the USA, Canada, the UK, the Middle East, India, and 20+ other countries. With global/ local recruiters, geo-specific and industry-specific talent pools, and data-driven hiring processes, we optimize recruitment benefits and reduce costs! We have developed our presence in 25+ countries, worked with 9000+ global clients, and fulfilled over 30,000+ successful recruitment projects.`
                                    </Typography>
                                </Box>


                            </Paper>
                        </Box>

                        <Box mb={3} >



                            <GenericCard data={franchiseDetails2} heading="" width="100%" />

                        </Box>

                        <Box mb={3}>



                            <GenericCard data={franchiseDetails3} heading="" width="100%" />

                        </Box>

                        <Box >
                            <Paper elevation={3} style={{ height: 'auto', overflowY: 'auto', borderRadius: { radius1 } }}>

                                < BusinessInterestForm />
                            </Paper>

                        </Box>

                    </Grid>

                    {/* Second Advertisement */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} style={{ height: 'auto', borderRadius: { radius1 } }}>
                            <img
                                src="/images/Adv1.svg"
                                alt="advertisement"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />

                        </Paper>
                        <Paper elevation={3} style={{ height: 'auto', overflowY: 'auto', borderRadius: { radius1 } }} >
                            <img
                                src="/images/Adv3.svg"
                                alt="advertisement"
                            // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />

                        </Paper>

                    </Grid>

                </Grid>
            </Container >
        </Box >
    );
};

export default DealersDetailedPage;
