import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { isLoggedInState, perInfoState } from '../recoil/profile-atom';
import { useRecoilState } from 'recoil';

const ProtectedRoute = ({ element, allowedRoles }) => {
    // const { auth } = useContext(AuthContext);
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
    const [perInfo, setPerInfo] = useRecoilState(perInfoState);


    console.log(" login role =>  " + allowedRoles)
    console.log(" allowed role => " + perInfo?.roles)

    const isAuthorised = isLoggedIn &&
        perInfo?.roles &&
        allowedRoles.some(role => perInfo?.roles?.includes(role));

    return isAuthorised ? (
        element
    ) : (
        <Navigate to="/unauthorised" state={{ from: location }} />
    );
};

export default ProtectedRoute;
