

import React, { useEffect, useState } from 'react';
import CustomInput from '../../../helpers/custom-input';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Box, Divider, Grid, Typography, Select, MenuItem, TextField, useMediaQuery, FormControl, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import ProgressStepper from '../progress/ProgressSteper';
import { useNavigate } from 'react-router-dom';
import { oneFranHubtheme } from '../../../theme/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonGroup from '../../../helpers/CustomRadioButton';
import CustomRadioButton from '../../../helpers/CustomRadioButton';
import CustomCheckbox from '../../../helpers/CustomCheckBox';
import { Label } from '@mui/icons-material';
import CustomButton from '../../../helpers/CustomButton';
import { OFAddress, OFAppleReminder, OFBackpack1, OFBriefcase, OFCalendar, OFCalendar2, OFCastle, OFCompany, OFCountry, OFDescription, OFEditIcon, OFHouse, OFHouse4, OFIndustry, OFLabour, OFLegalDocument1, OFLocation, OFMailAtSign, OFMailIcon, OFManager, OFMapsLocation, OFPhone, OFRoadLocation, OFRupeeIcon, OFSector, OFStore, OFUser, OFWebDesign, OFWebsiteLink, OFYesNoIcon } from '../../../icons/CustomIcon';
import CustomDropDown from '../../../helpers/dropdown/CustomDropdown';
import CustomInputPhone from '../../../helpers/CustomInputPhone';

import { toastError } from '../../../utils/toast-notify';

import { getApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import { fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';
import MultiSelectDropdown from '../../../poc/MultiSelectDropdown';
import MultiSelectChip from '../../../helpers/dropdown/MultiSelectDropdown';
import MultipleSelectChip from '../../../helpers/dropdown/MultiSelectChip';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import CustomDropdownSingle from '../../../helpers/dropdown/CustomDropdownSingle';
import CustomMultiLineInput from '../../../helpers/CustomMultiLineInput';

import ProgressStepperInvestor from '../progress/ProgressSteperInvestor';
import ProgressSteperSellMyBusiness from '../progress/ProgressSteperSellMyBusiness';




const SOwnerDetailsForm = ({ control, errors, setValue, watch, getValues,

    countries,
    cities,
    states,

    setSelectedCity,
    setSelectedCountry,
    setSelectedState,
    isDashboardMode,
    setIsEditing,
    isEditing,



}) => {


    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));



    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>

            {!isDashboardMode &&
                <>
                    <Box paddingX={6} paddingY={3}
                        sx={{ backgroundColor: '#E7F0FF' }}>
                        <Typography variant="h5" sx={{ color: "#0D2659" }}>Business on Sales</Typography>


                    </Box>
                    <Box ml={2} mr={2}>
                        {!isMobile &&
                            <>
                                <ProgressSteperSellMyBusiness />
                                <Divider />
                            </>
                        }

                    </Box>


                </>
            }

            <Box
                paddingX={6}

                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={2}>
                    <Typography variant="TITLE1">Owner Information and Contact details</Typography>

                    {/* {isDashboardMode &&
                        <IconButton onClick={toggleEdit} style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                    } */}
                    {isDashboardMode && (
                        <IconButton
                            onClick={toggleEdit}
                            style={{
                                float: 'right',
                                padding: '8px',
                                backgroundColor: '#E5EEFF',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <OFEditIcon />
                        </IconButton>
                    )}



                </Box>
                {isDashboardMode && <Box mb={2}>
                    <Divider />
                </Box>}

                <Grid container spacing={4}>





                    <Grid item xs={12} md={4}>
                        <Controller
                            name="ownerName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Owner Name"
                                    fullWidth sicon={<OFUser />}
                                    required
                                    variant="outlined"
                                    error={!!errors.ownerName}
                                    helperText={errors.ownerName ? errors.ownerName.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="emailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Email Id"
                                    required
                                    fullWidth sicon={<OFMailIcon />}
                                    variant="outlined"
                                    error={!!errors.emailId}
                                    helperText={errors.emailId ? errors.emailId.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Controller
                            name="contactNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Contact Number."
                                    fullWidth
                                    required
                                    sicon={< OFPhone />}
                                    countryCodeOptions={[{ value: '91', label: 'IND(+91)' }, { value: '1', label: 'US(+1)' }]}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={errors.contactNo?.phoneNumber?.message || ''}
                                    error={!!errors.contactNo}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />





                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="designation"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Designation"
                                    required
                                    fullWidth sicon={<OFLabour />}
                                    variant="outlined"
                                    error={!!errors.designation}
                                    helperText={errors.designation ? errors.designation.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="alternateEmailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Alternate Email Id"
                                    fullWidth sicon={<OFMailIcon />}
                                    variant="outlined"
                                    error={!!errors.alternateEmailId}
                                    helperText={errors.alternateEmailId ? errors.alternateEmailId.message : ''}
                                    placeholder={'Alternate Email Id'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>


                        <Controller
                            name="alternateContactNo"
                            control={control}
                            render={({ field }) => (
                                <CustomInputPhone
                                    label="Alternate Contact No."
                                    fullWidth

                                    sicon={< OFPhone />}
                                    countryCodeOptions={[{ value: '91', label: 'IND(+91)' }, { value: '1', label: 'US(+1)' }]}
                                    selectedCountryCode={field.value?.countryCode || ''}
                                    onCountryCodeChange={(newCountryCode) => {
                                        field.onChange({
                                            ...field.value,
                                            countryCode: newCountryCode,
                                        });
                                    }}
                                    value={field.value?.phoneNumber || ''}
                                    onChange={(newPhoneNumber) => {
                                        field.onChange({
                                            ...field.value,
                                            phoneNumber: newPhoneNumber,
                                        });
                                    }}
                                    helperText={errors.alternateContactNo?.phoneNumber?.message || ''}
                                    error={!!errors.alternateContactNo}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />





                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Controller
                            name="street"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Address"
                                    required

                                    fullWidth sicon={<OFAddress />}
                                    variant="outlined"
                                    error={!!errors.street}
                                    helperText={errors.street ? errors.street.message : ''}
                                    placeholder={'Address'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>



                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    sicon={<OFCountry />} // Replace with your actual icon
                                    label="Country"
                                    option={countries}
                                    defaultValue={field.value} // Ensure defaultValue is an object { id, name }

                                    error={!!errors.country?.name}
                                    helperText={errors.country?.name?.message || ''}
                                    changeCallBack={({ id, name }) => {


                                        setSelectedCountry(id);
                                        field.onChange({ id, name }); // Pass { id, name } to form state
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required sicon={<OFCountry />}
                                    fullWidth
                                    label="State"
                                    option={states}
                                    defaultValue={field.value}
                                    error={!!errors.state?.name}
                                    helperText={errors.state?.name?.message || ''}
                                    placeholder="Select State"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedState(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />




                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <CustomDropDown
                                    {...field}
                                    required
                                    fullWidth
                                    label="City" sicon={<OFCountry />}
                                    option={cities}
                                    defaultValue={field.value}
                                    error={!!errors.city?.name}
                                    helperText={errors.city?.name?.message || ''}
                                    placeholder="Select City"
                                    changeCallBack={({ id, name }) => {

                                        setSelectedCity(id);
                                        field.onChange({ id, name });
                                    }}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Controller
                            name="pinCode"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    required
                                    label="PIN Code"
                                    fullWidth sicon={<OFCountry />}
                                    variant="outlined"
                                    error={!!errors.pinCode}
                                    helperText={errors.pinCode ? errors.pinCode.message : ''}
                                    placeholder={'Enter PIN Code'}
                                    isViewMode={!isEditing}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}> </Grid>










                </Grid>
            </Box>



        </>);
};

export default SOwnerDetailsForm;
